<template>
  <div class="content">
    <el-row class="bgW">
      <el-col :span="22">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
          <el-form-item label="成交日期">
            <el-date-picker
              v-model="value1"
              type="daterange"
              range-separator="至"
              class="w220"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="searchTime"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="客户名称" v-if="!clienId">
            <el-input
              v-model="search.consignee"
              maxlength="30"
              class="w84"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          <el-form-item label="门店名称" v-if="!clienId">
            <el-input
              v-model="search.shop_name"
              maxlength="30"
              class="w84"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          <el-form-item label="收款金额">
            <el-input
              v-model="search.low_total"
              maxlength="30"
              class="w84"
              placeholder="最小值"
              clearable
            />
            <span></span>
            <el-input
              v-model="search.large_total"
              maxlength="30"
              class="w84"
              placeholder="最大值"
              clearable
            />
          </el-form-item>
          <el-form-item label="业务类型">
            <el-select v-model="search.business_type" placeholder="请选业务类型" class="w120" clearable>
              <el-option
                v-for="item in options.business_type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="订单类型">
            <el-select v-model="search.order_type" placeholder="请选订单类型" class="w120" clearable>
              <el-option
                v-for="item in options.order_type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
    </el-row>
    <div class="accountCon">
        <div class="btnBox">
            <div v-if="userInfo.shop_id"></div>
        </div>
        <!--列表-->
        <el-row>
            <el-col :span="23">
            <el-table
                :data="list"
                size="small"
                border
                :cell-style="$style.cellStyle"
                :header-cell-style="$style.rowClass"
                @selection-change="handleSelectionChange"
            >
                <ElTableColumn v-if="!userInfo.shop_id" type="selection" width="50" />
                <ElTableColumn label="成交日期" prop="deal_time" width="100" />
                <ElTableColumn label="客户名称" prop="consignee" />
                <ElTableColumn label="客户K3代码" prop="king_no" />
                <ElTableColumn label="门店" prop="shop_name" />
                <ElTableColumn label="护理师" prop="sale_name" />
                <ElTableColumn label="收款方式" prop="refund_mode_name" width="130" />
                <ElTableColumn label="本金" prop="pay_price" />
               <ElTableColumn label="店转" prop="shop_transfer_price" />
                <ElTableColumn label="收款金额" prop="proceeds" />
                <ElTableColumn label="业务类型" prop="business_type" />
                <ElTableColumn label="订单类型" prop="order_type" />

                <ElTableColumn label="操作" fixed="right" width="160">
                <template slot-scope="{ row }">
                    <span v-if="row.refund_type_plus == 1" class="theme"  @click="payPop(row,'扫码盒子')">立即支付</span>
                    <span v-if="row.refund_type_plus == 3">
                      <span class="theme"  @click="payPop(row,'WeChat')">打印</span>
                      <!-- &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                      <span class="theme"  @click="payPop(row,'Alipay')">支付宝</span> -->
                    </span>
                </template>
                </ElTableColumn>
            </el-table>
            <div class="reveal" v-if="sum_ben_amount != 'noShow'">
                <span class="mr20">
                本金合计：
                <span class="peril">{{sum_ben_amount}}</span>
                </span>
                <span class="mr20">
                赠金合计：
                <span class="peril">{{sum_zeng_amount}}</span>
                </span>
                <span class="mr20">
                收款金额合计：
                <span class="peril">{{sum_ben_amount}}</span>
                </span>
            </div>
            <pagination
                class="page tc mt10"
                :total="page.total"
                :page.sync="page.page"
                :limit.sync="page.limit"
                @pagination="getList"
            />
            </el-col>
        </el-row>

        <el-dialog title="扫码盒子支付" :visible.sync="payBoxPop" width="40%">
          <el-form
            :inline="true"
            ref="payData"
            size="mini"
            :model="payData"
            label-width="150px"
            @submit.native.prevent
          >
            <el-input placeholder="请输入支付授权码" v-model="payData.authCode" class="w700">
              <template slot="prepend">支付授权码：</template>
            </el-input>

            <!-- <el-input placeholder="" v-model="payData.text" class="w700" v-show="false">
              <template slot="prepend">支付授权码：</template>
            </el-input> -->
          </el-form>

          <span slot="footer" class="dialog-footer">
            <el-button size="mini" @click="payBoxPop = false">取 消</el-button>
            <el-button size="mini" type="primary" @click="submitForm('payBox')">确 定</el-button>
          </span>

        </el-dialog>


        <!-- <el-dialog :title="titleMap[titleName]" :visible.sync="payQrcodePop" width="40%"> -->
        <el-dialog title="打印小票" :visible.sync="payQrcodePop" width="40%">
          <!-- printStart -->
          <div id="printBox" style="margin-left: 30%">
            <div>==================</div>
            <div>====={{orderData.order_time}}=====</div>
            <div>==================</div>
            <div>订单编号：</div>
            <div>{{orderData.refund_order_no}}</div>
            <div>==================</div>
            <div>服务中心：</div>
            <div>{{orderData.shop_name}}</div>
            <div>==================</div>
            <div>客户姓名：</div>
            <span>{{orderData.customer_name}}</span>
            <div>==================</div>
            <div>实收金额：</div>
            <span>{{orderData.pay_amount}}</span>
            <div>==================</div>
            <div>====={{orderData.order_time}}=====</div>
            <div>==================</div>
            <div style="width:100%; height:20px;"></div>
            <div>
              <span>订单二维码：</span>
            </div>
            <div style="width:100%; height:20px;"></div>
            <div id="qrCode" ref="qrCode"></div>
            <div style="width:100%; height:20px;"></div>
            <div>==================</div>
            <div>====={{orderData.order_time}}=====</div>
            <div>==================</div>
          </div>
          <!-- printEnd -->
          <button @click="printHtml()">打印</button>
        </el-dialog>

    </div>
  </div>


</template>

<script>
import { mapGetters } from "vuex";
import pagination from "@/components/Pagination";
import { refundList,unpaidList } from "@/api/order";
import { payBox,getPayUrl } from "@/api/pay";
import QRCode from "qrcodejs2";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Search {
  start_time = "";
  end_time = "";
  consignee = "";
  shop_name = "";
  low_total = "";
  large_total = "";
  business_type = "";
  order_type = "";
}
class Options {
  business_type = [
    { id: 1, name: "疤痕" },
    { id: 2, name: "项目" }
  ];
  order_type = [
    { id: 1, name: "新单" },
    { id: 2, name: "补单" }
  ];
}

// class payData {
//   order_id = "";
//   authCode = "";
// }

export default {
  name: "OrderList",
  components: {
    pagination,
    QRCode
  },
  props: {
    clienId: {
      type: String,
      default: ""
    },
    detail: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
        search: new Search(), // 检索条件
        options: new Options(), // 选项
        page: new Page(), // 分页
        list: [], // 数据展示
        idList: "",
        value1: [],
        sum_ben_amount: "noShow",
        sum_zeng_amount: "",
        payBoxPop:false,
        payQrcodePop:false,
        payData: {
          order_id:"",
          authCode:"",
        }, // 支付信息
        // creatQrCode: new creatQrCode()
        titleMap:{
          WeChatTitle:'微信支付二维码',
          AlipayTitle:'支付宝支付二维码',
        },
        titleName:"",
        orderData:{
          refund_order_no:"",
          shop_name:"",
          customer_name:"",
          pay_amount:"",
          order_time:"",
        }
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    this.getList();
    // console.log(this.clienId)
  },
  methods: {


    // 获取列表
    getList(option) {
      if (option == "search") this.page = new Page();
      unpaidList({
        ...this.search,
        ...this.page,
        type: 3,
        uid: this.clienId ? this.clienId : "",
        status: "-1",
        is_pay: "0",
      }).then(res => {
        this.list = res.list;
        this.page.total = res.dataCount;
        if (this.search.end_time) {
          this.sum_ben_amount = res.sum_ben_amount;
          this.sum_zeng_amount = res.sum_zeng_amount;
        } else {
          this.sum_ben_amount = "noShow";
        }
      });
    },
    // 日期搜索
    searchTime(val) {
      if (val) {
        this.search.start_time = val[0];
        this.search.end_time = val[1];
      } else {
        this.search.start_time = "";
        this.search.end_time = "";
      }
    },
    // 调整提交
    submitForm(formName) {

      // console.log(this.payData);

      this.hint("立即支付", "提交支付");

    },

    // 提示
    hint(text, type) {
      this.$confirm(`${text},是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          // console.log();
          if (type == "提交支付") {
            this.payFunction();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },

    // 确定支付
    // payFunction() {
    //   // console.log(this.payData);
    //   payBox({
    //     ...this.payData
    //   }).then(res => {
    //     console.log(res);
    //     // this.payBoxPop = false;
    //     // this.unpaidList();
    //   });
    // },

    async payFunction(){
      const loading = this.$loading({
        lock:true,
        text:"",
        spinner:"el-icon-loading",
        background:"rgba(0, 0, 0, 0.7)"
      });
      try {
        const res = await payBox({ ...this.payData });
        loading.close();
        this.$message({
          type:"success",
          message: res.msg
        });

        this.payBoxPop = false;
        this.getList();
      } catch (e) {
        loading.close();
      }
    },

    // 点击调整日期
    payPop(date,type){
        // console.log(date);
        if(date.refund_type_plus == '1'){
          this.payBoxPop = true;

          this.payData.order_id = date.id;
        }

        if(date.refund_type_plus == '3'){

            this.titleName = type+'Title';

            this.payQrcodePop = true;

            this.payData.order_id = date.id;

            this.payData.type = type;

            // console.log(this.payData);
            // 请求支付链接
            getPayUrl({
              // order_id: date.id
              ...this.payData
            }).then(res=>{
              console.log(res.data);
              this.orderData.refund_order_no = res.data.refund_order_no;
              this.orderData.shop_name = res.data.shop_name;
              this.orderData.customer_name = res.data.customer_name;
              this.orderData.pay_amount = res.data.pay_amount;
              this.orderData.order_time = res.data.time;


              let cusid = res.data.cusid;
              let appid = res.data.appid;
              let pay_amount = res.data.pay_amount;
              let refund_order_no = res.data.refund_order_no;

              let shop_id = res.data.shop_id;
              let shanghu_type = res.data.shanghu_type;

              // +'&appid='+appid+'&pay_amount='+pay_amount+'&refund_order_no='+refund_order_no+'&shop_name='+shop_name+'&shop_id='+shop_id+'&shanghu_type='+shanghu_type

              let url = 'https://sunburn.178590.com/return/payH5/index.php?refund_order_no='+refund_order_no+'&shop_id='+shop_id;// 正式
              // let url = 'http://burn.178590.com/return/payH5/index.php?refund_order_no='+refund_order_no+'&shop_id='+shop_id;// 测试
              // console.log(url);
              // return
              this.$nextTick(()=>{
                this.qrCodeCreate(url);
              })
            });



        }

    },

    // 改变表格选中状态
    handleSelectionChange(val) {
      this.idList = val.map(i => {
        return i.id;
      });
    },


    //创建二维码
    qrCodeCreate(url) {
      // console.log('生成支付二维码的链接：'+this.$refs.qrCode);

      this.$refs.qrCode.innerHTML = "";

      var qrcode = new QRCode(this.$refs.qrCode, {
        // text: 'xxxx', // 需要转换为二维码的内容
        text: url, // 需要转换为二维码的内容
        width: 150,
        height: 150,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    },

    // // 清空二维码
    // closeCode() {
    //   this.$refs.qrCode.innerHTML = "";
    // },
    printHtml() {
        let bodyHtml = window.document.body.innerHTML;


        let printBox = document.getElementById('printBox').innerHTML;
        // console.log('2222'+printBox);
        window.document.body.innerHTML = printBox;
        window.print();
        location.reload();
        // window.document.body.innerHTML = bodyHtml;
    }


  }
};
</script>

<style lang="scss" scoped>
.accountCon {
  background: #fff;
  padding: 10px 0px 80px;
  .btnBox {
    display: flex;
    justify-content: space-between;
  }
}
.reveal {
  text-align: right;
  padding: 20px 0;
  .peril {
    font-weight: bold;
  }
}
</style>
